<template>
  <b-sidebar
    sidebar-class="sidebar-lg"
    :visible="$store.state['formSidebar'].componentSidebarEdit"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    @change="enableSidebarUpdate"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Editar Opción</h5>
        <div>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
      </div>
      <b-form class="p-2" @submit.prevent="editOption">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre" label-class="text-left">
              <b-form-input
                id="name"
                type="text"
                v-model.trim="option.name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Metadata" label-class="text-left">
              <b-form-textarea
                rows="3"
                max-rows="8"
                v-model.trim="option.metadata"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col>
            <b-button type="submit" variant="primary">Guardar</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
  </b-sidebar>
</template>
<script>
import Container from "../FormContainer";
import useHandlerSidebar from "../useHandlerSidebar";
import useToastNotification from "@notification";
import store from "@/store";
import { StatusNotification } from "@/constants";
import actionName from "@actionName";
import {
  BFormTextarea,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BRow,
  BCol,
  BSidebar,
} from "bootstrap-vue";
export default {
  components: {
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BRow,
    BCol,
    BSidebar,
    Container,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const notification = useToastNotification();
    const {
      subscription: {
        option: { updateOption: updateOption },
      },
    } = actionName;
    const { enableSidebarUpdate } = useHandlerSidebar();

    const editOption = async () => {
      try {
        await store.dispatch(updateOption, props.option);
        notification.dispatchNotification(
          "Opción",
          StatusNotification.SUCCESS,
          "Opción actualizada"
        );
      } catch (error) {
        notification.dispatchNotification(
          "Opción",
          StatusNotification.ERROR,
          "No se pudo realizar la actualización"
        );
      }
    };

    return {
      enableSidebarUpdate,
      editOption,
    };
  },
};
</script>