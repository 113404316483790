<template>
  <container
    :error="error"
    title="Mantenimiento de opciones para planes"
    titleCreateButton="Nueva Opción"
  >
    <template #body>
      <b-table
        :fields="columns"
        :items="options"
        :busy="isDataLoading"
        responsive
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(isActive)="data">
          <b-badge pill :variant="data.value ? 'success' : 'danger'">
            {{ data.value ? "Activo" : "Desactivado" }}
          </b-badge>
        </template>
        <template #cell(actions)="row">
          <feather-icon
            icon="EyeIcon"
            class="cursor-pointer mr-1"
            size="20"
            @click="row.toggleDetails"
          />
          <feather-icon
            icon="EditIcon"
            class="cursor-pointer mr-1"
            size="20"
            @click="handleFormClick(row.item)"
          />
        </template>
        <template #row-details="row">
          <b-card title="Metadata">
            <b-card-text>
              {{ JSON.stringify(row.item?.metadata, null, 2) }}
            </b-card-text>
          </b-card>
        </template>
      </b-table>
    </template>
    <template #sidebar>
      <create-option />
      <edit-option :option="option" />
    </template>
  </container>
</template>
<script>
import {
  BBadge,
  BSpinner,
  BTable,
  BAlert,
  BCard,
  BCardText,
  BButton,
} from "bootstrap-vue";
import Container from "../Container.vue";
import useOption from "./useOption.js";
import CreateOption from "./Create.vue";
import EditOption from "./Edit.vue";
export default {
  components: {
    BBadge,
    BSpinner,
    BTable,
    BAlert,
    BCard,
    BCardText,
    BButton,
    Container,
    CreateOption,
    EditOption,
  },
  setup() {
    const { option, error, isDataLoading, columns, options, handleFormClick } =
      useOption();
    return {
      option,
      error,
      isDataLoading,
      columns,
      options,
      handleFormClick,
    };
  },
};
</script>