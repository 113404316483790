import { ref, provide, onBeforeMount } from "@vue/composition-api";
import actionName from "@actionName";
import store from "@/store";
import useHandlerSidebar from "../useHandlerSidebar";

export default function useOption() {
  const options = ref([]);
  const error = ref("");
  const isDataLoading = ref(true);
  const blankOption = ref({
    name: "",
    metadata: "",
  });
  const option = ref(JSON.parse(JSON.stringify(blankOption)));
  const columns = [
    {
      key: "name",
      label: "Nombre",
    },
    {
      key: "actions",
      label: "Acciones",
    },
  ];
  const {
    subscription: {
      option: { getAll: getAllOptions },
    },
  } = actionName;
  const { enableSidebarUpdate } = useHandlerSidebar();
  const handleFormClick = (optionData) => {
    enableSidebarUpdate(true);
    option.value = optionData;
  };

  const _getAllOptions = async () => {
    try {
      options.value = await store.dispatch(getAllOptions);
    } catch (err) {
      error.value = err.message;
    } finally {
      isDataLoading.value = false;
    }
  };
  const onSearch = (query) => {
    console.log("Buscando -> " + query);
  };
  provide("onSearch", onSearch);

  onBeforeMount(async () => {
    await _getAllOptions();
  });

  return {
    option,
    error,
    isDataLoading,
    columns,
    options,
    handleFormClick,
  };
}
