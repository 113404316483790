import { ref } from "@vue/composition-api";
import store from "@/store";
import actionName from "@actionName";
import useToastNotification from "@notification";
import { StatusNotification } from "@/constants";

export default function useCreateOption() {
  const notification = useToastNotification();
  const option = ref({
    name: "",
    metadata: "",
  });
  const {
    subscription: {
      option: { storeOption: storeOption },
    },
  } = actionName;

  const createOption = async () => {
    try {
      await store.dispatch(storeOption, option.value);
      notification.dispatchNotification(
        "Opción",
        StatusNotification.SUCCESS,
        "Opción registrado"
      );
    } catch (error) {
      notification.dispatchNotification(
        "Opción",
        StatusNotification.ERROR,
        "No se pudo completar el registro"
      );
    }
  };

  return {
    option,
    createOption,
  };
}
