var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('container', {
    attrs: {
      "error": _vm.error,
      "title": "Mantenimiento de opciones para planes",
      "titleCreateButton": "Nueva Opción"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('b-table', {
          attrs: {
            "fields": _vm.columns,
            "items": _vm.options,
            "busy": _vm.isDataLoading,
            "responsive": ""
          },
          scopedSlots: _vm._u([{
            key: "table-busy",
            fn: function fn() {
              return [_c('div', {
                staticClass: "text-center text-danger my-2"
              }, [_c('b-spinner', {
                staticClass: "align-middle"
              }), _c('strong', [_vm._v("Loading...")])], 1)];
            },
            proxy: true
          }, {
            key: "cell(isActive)",
            fn: function fn(data) {
              return [_c('b-badge', {
                attrs: {
                  "pill": "",
                  "variant": data.value ? 'success' : 'danger'
                }
              }, [_vm._v(" " + _vm._s(data.value ? "Activo" : "Desactivado") + " ")])];
            }
          }, {
            key: "cell(actions)",
            fn: function fn(row) {
              return [_c('feather-icon', {
                staticClass: "cursor-pointer mr-1",
                attrs: {
                  "icon": "EyeIcon",
                  "size": "20"
                },
                on: {
                  "click": row.toggleDetails
                }
              }), _c('feather-icon', {
                staticClass: "cursor-pointer mr-1",
                attrs: {
                  "icon": "EditIcon",
                  "size": "20"
                },
                on: {
                  "click": function click($event) {
                    return _vm.handleFormClick(row.item);
                  }
                }
              })];
            }
          }, {
            key: "row-details",
            fn: function fn(row) {
              var _row$item;
              return [_c('b-card', {
                attrs: {
                  "title": "Metadata"
                }
              }, [_c('b-card-text', [_vm._v(" " + _vm._s(JSON.stringify((_row$item = row.item) === null || _row$item === void 0 ? void 0 : _row$item.metadata, null, 2)) + " ")])], 1)];
            }
          }])
        })];
      },
      proxy: true
    }, {
      key: "sidebar",
      fn: function fn() {
        return [_c('create-option'), _c('edit-option', {
          attrs: {
            "option": _vm.option
          }
        })];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }