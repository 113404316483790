<template>
  <b-sidebar
    sidebar-class="sidebar-lg"
    :visible="$store.state['formSidebar'].componentSidebarCreate"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    @change="enableSidebarCreate"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Nueva Opción</h5>
        <div>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
      </div>
      <b-form class="p-2" @submit.prevent="createOption">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre" label-class="text-left">
              <b-form-input
                id="name"
                type="text"
                v-model.trim="option.name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Metadata" label-class="text-left">
              <b-form-textarea
                rows="3"
                max-rows="8"
                v-model.trim="option.metadata"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col>
            <b-button type="submit" variant="primary">Guardar</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
  </b-sidebar>
</template>
<script>
import Container from "../FormContainer";
import useCreateOption from "./useCreateOption";
import useHandlerSidebar from "../useHandlerSidebar";
import {
  BFormTextarea,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BRow,
  BCol,
  BSidebar,
} from "bootstrap-vue";
export default {
  components: {
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BRow,
    BCol,
    BSidebar,
    Container,
  },
  setup() {
    const { option, createOption } = useCreateOption();
    const { enableSidebarCreate } = useHandlerSidebar();
    return {
      option,
      createOption,
      enableSidebarCreate,
    };
  },
};
</script>